v1=0
v2=0
v3=0
 
// function verif(){
	
// saisie1 = document.Emprunt.capital.value

// if (saisie1.length>0){
// x=saisie1*1
// 	if (x>2000000){
//   	 	alert("capital < 2.000.000 svp...")
// 		return
//    	}
// 	else if (x<1000){
//    		alert("capital => 1000 svp...")
//   		return
//    	}
// }

// saisie1 = document.Emprunt.duree.value

// if (saisie1.length>0){
// x=saisie1*1
// 	if (x>30){
//    		alert("Durée < 30 ans svp...")
//    		return
//    	}
// 	else if (x<1){
//    		alert("Durée => 1 an svp...")
//    		return
//    	}
// }

// saisie1 = document.Emprunt.taux.value

// if (saisie1.length>0){
// x=saisie1*1
// 	if (x>25){
//    		alert("taux < 25 % svp...")
//    		return
//    	}
// 	else if (x<1){
//    		alert("taux => 1 % svp...")
//    		return
//    	}
// }
// calcul()
// }

function calcul()
 {var m, k, t, n, x, cout, nbs
  nbs = 0 
  m = 0
  k = 0
  t = 0
  n = 0

  saisie1 = document.Emprunt.capital.value
  s1=""
  for (var i=0; i<saisie1.length; i++)
      {chiffre = saisie1.charAt(i)
       if (chiffre==",")
          chiffre="."
       if ((chiffre<"0" || chiffre>"9") && chiffre!=".")
          continue
        else
          s1=s1+chiffre
      }
  if (s1=="")
     nbs++
  v1=s1  

  saisie2 = document.Emprunt.duree.value
  s2="" 
  testalpha=0
  vs=""
  for (var i=0; i<saisie2.length; i++)
      {chiffre = saisie2.charAt(i)
       if ((chiffre=="," || chiffre=="a" || chiffre=="y" || chiffre=="A" || chiffre=="Y") && testalpha==0)
          chiffre="."
       if (chiffre==" ")
          continue         
       if ((chiffre<"0" || chiffre>"9") && chiffre!=".")
          {testalpha=1
           continue
          }
        else
          if (testalpha==0)
             s2=s2+chiffre
           else
             vs=vs+chiffre
      }
      if (vs!="")
         {vs2=Math.round(vs*8.3)
          if (vs2<10)
             vs2="0"+vs2 
          s2=s2+vs2
         }
      if (s2=="")
         nbs++
   v2=s2  
  
  saisie3 = document.Emprunt.taux.value
  s3="" 
  for (var i=0; i<saisie3.length; i++)
      {chiffre = saisie3.charAt(i)
       if (chiffre==",")
          chiffre="."
       if ((chiffre<"0" || chiffre>"9") && chiffre!=".")
          continue
        else
          s3=s3+chiffre
      }
  if (s3=="") 
     nbs++
  v3=s3

  saisie4 = document.Emprunt.mensu.value
  s4="" 
  for (var i=0; i<saisie4.length; i++)
      {chiffre = saisie4.charAt(i)
       if (chiffre==",")
          chiffre="."
       if ((chiffre<"0" || chiffre>"9") && chiffre!=".")
          continue
        else
          s4=s4+chiffre
      }
  if (s4=="")
     nbs++

  if (nbs!=1)
     alert("Il faut remplir 3 champs !!!")
   else if (s1=="")    // Capital
        {n = s2*12
         t = s3/1200
         m = s4
         x = (m*((1-(Math.pow((1+t),-n)))))/t
         cout = Math.round((m*n)-x)
         x= Math.round(x)
         document.Emprunt.capital.value=x
         v1=x
        } 
   else if (s2=="")   // Durée
        {k = s1
         t = s3/1200
         m = s4
         var trouver, test_duree, moitie, c
         trouver = 0
         test_duree = 240
         moitie = 240
         for (i=0;i<40;i++)
             {c = (k*t)/(1-(Math.pow((1+t),-test_duree)))
              c=Math.round(c)
              if (c==m)
                 {trouver = 1
                  break
                 }
         else if (c<m)
                 {moitie = moitie/2
                  test_duree = test_duree-moitie
                 } 
         else
                 {moitie = moitie/2
                  test_duree = test_duree+moitie
                 }
             }
         if (trouver==0)
             {
             alert("Impossible !")
             } 
        else 
             {c=test_duree
             an = Math.floor(c/12)
             mois = Math.round(c%12)
             if (mois==12)
                {mois=0
                 an++
                }
             if (mois==0)
                {
                result=an+" ans"
                } 
              else
                {
                result=an+" ans "+mois+" mois"
                }
                document.Emprunt.duree.value=result
                v2=result
                cout=(m*c)-s1
                cout=Math.round(cout) 
                
             } 

        } 
   else if (s3=="")  // Taux
        {k = s1
         n = s2*12
         m = s4
         var trouver, test_taux, moitie, c, d
         trouver = 0
         test_taux = 0.033
         moitie = 0.033
         for (i=0;i<40;i++)
             {c = (k*test_taux)/(1-(Math.pow((1+test_taux),-n)))
              c=Math.round(c)
              if (c==m)
                 {trouver = 1
                  break
                 }
         else if (c>m)
                 {moitie = moitie/2
                  test_taux = test_taux-moitie
                 } 
         else
                 {moitie = moitie/2
                  test_taux = test_taux+moitie
                 }
             }
         if (trouver== 0)
             {
             alert("Impossible !")
             } 
        else 
             {c=test_taux
             c=c*1200
             d=Math.floor(c)
             e=c-d
             if (e!=0)
                {e=e*100
                 e=Math.round(e)
                 if (e==100)
                    {e="00"
                     d++
                    }
                 result=d+"."+e+" %"
                }
               else
                 {result=d+" %"
                 }
                document.Emprunt.taux.value=result
                v3=result
                cout=(m*n)-k
                cout=Math.round(cout) 
                document.Emprunt.cout.fgcolor ="#0000FF"
             } 
        } 
   else if (s4=="")  // Mensualité
        {k = s1
         n = s2*12
         t = s3/1200
         x = (k*t)/(1-(Math.pow((1+t),-n)))
         cout = Math.round((x*n)-k)
         x = Math.round(x)
         document.Emprunt.mensu.value=x
   } 
z1=v1
z2=v2
z3=v3
}