/*jshint browser:true, undef: true, unused: true, jquery: true */

var $container;
var filters = {};

$('.programme__filters--checkbox').click(function(e){
	e.preventDefault();
	var $checkbox = $( "input", $(this) );
	$(this).toggleClass('active');
	$checkbox.prop("checked", $checkbox.is(':checked') ? null:'checked');
});

var getIsotope = function(){

	$container = $('#progam__container');

	$container.imagesLoaded( function() {
		$.fn.matchHeight._update();
		$container.isotope({
			layoutMode: 'fitRows'
		});
	});

	var refreshIsotope = function(){
		var comboFilter = getComboFilter( filters );
		$container.imagesLoaded( function() {
			$.fn.matchHeight._update();
			$container.isotope({ 
				filter: comboFilter 
			}, function noResultsCheck() {
				var noItems = $('<div class="item no-results col-1-3 program-thumb "><div class="program-thumb__content harmonize"><div class="program-thumb__inner"><p class="program-thumb__title font--bigger"><strong>Aucun résultat</strong></p></div></div></div>');
				var yesItems = $('.no-results');
				var numItems = $('.item:not(.isotope-hidden)').length;
				if (numItems == 0) {
					$container.append(noItems).isotope( 'appended', noItems );
				}else{ 
					$container.isotope( 'remove', yesItems);
				}
			});
		});
	}

	// change
	$('.programme__filters--checkbox').unbind('click').click(function(e){
		e.preventDefault();
		var $checkbox = $( "input", $(this) );
		$(this).toggleClass('active');
		$checkbox.prop("checked", $checkbox.is(':checked') ? null : 'checked');
		manageCheckbox( $checkbox );
		refreshIsotope();
	});

	$('.programme__filters__select').on( 'change', function( jQEvent ) {
		var $checkbox = $( "option:selected", jQEvent.target );
		manageCheckbox( $checkbox );
		refreshIsotope();
	});

	// load
	if( $( "input[name='prestige']" ).is(':checked') ){
		var $checkbox = $( "input[name='prestige']" );
		manageCheckbox( $checkbox );
		refreshIsotope();
	}

	if( $('.programme__filters__select option:selected').length > 0 ){
		$('.programme__filters__select').each(function(){
			var $checkbox = $( "option:selected", $(this) );
			manageCheckbox( $checkbox );
		});
		refreshIsotope();
	}

};


function getComboFilter( filters ) {
	var i = 0;
	var comboFilters = [];
	var message = [];

	for ( var prop in filters ) {
		message.push( filters[ prop ].join(' ') );
		var filterGroup = filters[ prop ];
	// skip to next filter group if it doesn't have any values
		if ( !filterGroup.length ) {
			continue;
		}
		if ( i === 0 ) {
		// copy to new array
			comboFilters = filterGroup.slice(0);
		} else {
			var filterSelectors = [];
			// copy to fresh array
			var groupCombo = comboFilters.slice(0); // [ A, B ]
			// merge filter Groups
			for (var k=0, len3 = filterGroup.length; k < len3; k++) {
				for (var j=0, len2 = groupCombo.length; j < len2; j++) {
					filterSelectors.push( groupCombo[j] + filterGroup[k] ); // [ 1, 2 ]
				}
			}
			// apply filter selectors to combo filters for next group
			comboFilters = filterSelectors;
		}
		i++;
	}

	var comboFilter = comboFilters.join(', ');
	return comboFilter;
}

function manageCheckbox( $checkbox ) {
	var checkbox = $checkbox[0];

	var group = $checkbox.parents('.programme__filters').attr('data-group');
	// create array for filter group, if not there yet
	var filterGroup = filters[ group ];
	if ( !filterGroup ) {
		filterGroup = filters[ group ] = [];
	}

	// index of
	var index = $.inArray( $checkbox.data('filter'), filterGroup );

	if ( index === -1 ) {
		// add filter to group
		filters[ group ] = [];
		filters[ group ].push( $checkbox.data('filter') );
	}else{
		filters[ group ].splice( index, 1 );
		// $checkbox.removeClass('active');
	}
}
